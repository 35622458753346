import { Board } from '@air/api/types';
import { useEffect } from 'react';

import { useAppliedCustomFieldsFilters } from '~/hooks/filters/useAppliedCustomFieldsFilters';
import { useAppliedTagsFilters } from '~/hooks/filters/useAppliedTagsFilters';
import { useSetFiltersFromContextToRedux } from '~/hooks/filters/useSetFiltersFromContextToRedux';
import { useFiltersModifiedManuallyContext } from '~/providers/FiltersModifiedManuallyProvider';
import { useGetPublicFilterOptions } from '~/swr-hooks/filters/useGetPublicFilterOptions';
import { useGetPublicFiltersEntities } from '~/swr-hooks/filters/useGetPublicFiltersEntities';

export const useSetPublicFiltersFromContextToRedux = ({ boardId }: { boardId?: Board['id'] }) => {
  const { getFiltersModifiedManually, setFiltersModifiedManually } = useFiltersModifiedManuallyContext();

  const { appliedTagsFilters } = useAppliedTagsFilters();
  const { appliedCustomFieldsFilters } = useAppliedCustomFieldsFilters();

  const { data: options } = useGetPublicFilterOptions({ boardId });
  const { data: entities } = useGetPublicFiltersEntities({
    tagLabels: appliedTagsFilters,
    customFields: appliedCustomFieldsFilters,
  });

  const { setFiltersFromContextToRedux } = useSetFiltersFromContextToRedux({
    options,
    entities,
  });

  useEffect(() => {
    if (!!options && !!entities && getFiltersModifiedManually()) {
      setFiltersModifiedManually(false);
      setFiltersFromContextToRedux();
    }
  }, [options, entities, setFiltersFromContextToRedux, getFiltersModifiedManually, setFiltersModifiedManually]);

  return {
    setFiltersFromContextToRedux,
  };
};
