import { FiltersTrackingLocation, useTrackReturnedEmptyFiltersResult } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { Board, BoardsListOptions, ClipsListOptions, SortDirection } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { useSelector } from 'react-redux';

import { getPublicSearchTotalKey } from '~/constants/react-query-keys';
import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useFiltersContext } from '~/providers/FiltersProvider';
import { currentSortFieldDirectionSelector, currentSortFieldNameSelector } from '~/store/configViews/selectors';
import { useCanShowBoards } from '~/swr-hooks/gallery/useCanShow';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';
import { getTypesToFetchFromParams } from '~/swr-hooks/search/utils';
import { filterParamsToTrackingType } from '~/utils/filters/filtersToUrlParamsUtils';
import { insertIf } from '~/utils/insertIf';

const getBoardsTotal = async (shortId: string, boardId: Board['id'], params: BoardsListOptions) => {
  const response = await ShortUrl.listChildrenBoardsByShortId(shortId, boardId, {
    ...params,
    limit: 1,
  });

  return response.total;
};

const getClipsTotal = async (shortId: string, boardId: Board['id'], params: ClipsListOptions) => {
  const response = await ShortUrl.searchClipsByShortId(shortId, boardId, {
    ...params,
    limit: 1,
  });
  return response.data.total;
};

export const usePublicSearchTotals = ({ trackLocation }: { trackLocation: FiltersTrackingLocation }) => {
  const currentBoardId = useURLBoardIdSelector();
  const { shortId } = useShortIdContext();
  const { typesParam } = useFilterParams();
  const clipsFetchParams = useClipSearchParams();
  clipsFetchParams.types = getTypesToFetchFromParams(typesParam);
  const boardsFetchParams = useBoardSearchParams();
  const sortDirection = useSelector(currentSortFieldDirectionSelector) ?? SortDirection.asc;
  const sortField = useSelector(currentSortFieldNameSelector);
  const { canShowBoards } = useCanShowBoards();
  const { trackReturnedEmptyFiltersResult } = useTrackReturnedEmptyFiltersResult();
  const {
    filters: { customFields, ...filters },
  } = useFiltersContext();

  const fetchParams = {
    ...clipsFetchParams,
    ...boardsFetchParams,
    sortField: {
      name: sortField,
      direction: sortDirection || SortDirection.asc,
    },
    sortBy: undefined,
    limit: 1,
  };

  const swrKeyPrams = {
    ...typesParam,
    ...fetchParams,
    canShowBoards,
  };

  const { data } = useQuery({
    queryKey: getPublicSearchTotalKey(qs.stringify(swrKeyPrams), shortId),

    queryFn: async () => {
      const response = await Promise.all([
        ...insertIf(canShowBoards, () => [getBoardsTotal(shortId, currentBoardId!, boardsFetchParams)]),
        getClipsTotal(shortId, currentBoardId!, fetchParams),
      ]);

      const total = response.reduce((acc, curr) => acc + curr, 0);

      if (!total) {
        trackReturnedEmptyFiltersResult({
          types: filterParamsToTrackingType({ ...filters, ...customFields }),
          location: trackLocation,
        });
      }

      return { total };
    },
  });

  return {
    total: data?.total,
  };
};
