import { useTrackClickedPublicBoardLink } from '@air/analytics';
import { useAirModal } from '@air/provider-modal';
import Router from 'next/router';
import { memo } from 'react';

import { PublicTopBarAccountInfo } from '~/components/Layouts/PublicBoardLayout/PublicTopBarAccountInfo';
import { SignupModal } from '~/components/Modals/SignupModal/SignUpModal';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { useAccountContext } from '~/providers/AccountProvider';

import { AirLogoLink } from '../shared/components/AirLogoLink';

export const DefaultBanner = memo(() => {
  const { workspaceImage, workspaceName } = usePublicWorkspace();
  const { data: account } = useAccountContext();
  const [showSignUpModal] = useAirModal(SignupModal);
  const { trackClickedPublicBoardLink } = useTrackClickedPublicBoardLink();

  return (
    <div className="relative flex size-full items-center justify-between bg-pigeon-900 px-2">
      <div className="flex items-center justify-start">
        <img alt={`Logo for ${workspaceName}`} className="size-8 min-w-[32px] rounded" src={workspaceImage} />
        <p className="mx-2 truncate text-16 font-medium text-grey-11">{workspaceName}</p>
      </div>
      <div className="flex shrink-0 items-center gap-4 ">
        {account ? (
          <PublicTopBarAccountInfo account={account} />
        ) : (
          <button
            className="cursor-pointer px-0.5 text-14 font-semibold text-grey-10 hover:underline"
            onClick={() => {
              showSignUpModal({
                ssoCallbackData: {
                  url: Router.asPath,
                },
                accountCreationSource: 'share-link',
              });
              trackClickedPublicBoardLink({
                name: 'clicked login',
                page_area: 'banner',
                page_type: 'public board',
              });
            }}
          >
            Sign up
          </button>
        )}
        <AirLogoLink />
      </div>
    </div>
  );
});

DefaultBanner.displayName = 'DefaultBanner';
