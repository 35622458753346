import { memo } from 'react';

import { ItemProps } from '~/components/Gallery/types';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { AssetMetaData, AssetMetaDataProps } from '~/components/TableView/NameCell/AssetNameCell/AssetMetaData';
import { TableAssetTitle } from '~/components/TableView/NameCell/NameCellContent/TableAssetTitle';
import { canSeeAssetVersions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export interface PublicAssetNameCellTitleProps
  extends Pick<AssetMetaDataProps, 'assetVersionCount' | 'openCommentCount'> {
  onClick: ItemProps['onItemClick'];
  onCmdClick: ItemProps['onItemCmdClick'];
  onCommentsClick?: () => void;
  onVersionsClick?: () => void;
  title: string;
  id: string;
  pagesCount: number;
}

export const PublicAssetNameCellTitle = memo(
  ({
    onClick,
    onCmdClick,
    title,
    assetVersionCount,
    onCommentsClick,
    onVersionsClick,
    openCommentCount,
    pagesCount,
  }: PublicAssetNameCellTitleProps) => {
    const { permissions } = usePublicPermissions();
    const canViewDiscussions = canViewAssetDiscussions(permissions);
    const canViewAssetVersions = canSeeAssetVersions(permissions);

    return (
      <div className="relative ml-3" data-disableselect="true">
        <TableAssetTitle title={title} onClick={onClick} onCmdClick={onCmdClick} />
        <AssetMetaData
          assetVersionCount={assetVersionCount}
          onCommentsClick={onCommentsClick}
          onVersionsClick={onVersionsClick}
          openCommentCount={openCommentCount}
          shouldShowDiscussions={canViewDiscussions}
          shouldShowVersions={canViewAssetVersions}
          pagesCount={pagesCount}
        />
      </div>
    );
  },
);

PublicAssetNameCellTitle.displayName = 'PublicAssetNameCellTitle';
