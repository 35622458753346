import { Air } from '@air/next-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import { memo, ReactNode, useMemo } from 'react';

import { PublicSignUpButton } from '~/components/PublicSignUpButton';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { Routes } from '~/constants/routes';
import { PUBLIC_TOP_BAR, PUBLIC_TOP_BAR_LOGO } from '~/constants/testIDs';

export interface PublicBoardTopBarProps {
  workspaceInfo?: ReactNode;
  accountInfo?: ReactNode;
}

export const PUBLIC_TOP_BAR_HEIGHT = 56;

export const PublicTopBar = memo(({ workspaceInfo, accountInfo }: PublicBoardTopBarProps) => {
  const { workspaceId } = usePublicWorkspace();
  const { whiteLabelWorkspaces } = useFlags<{
    whiteLabelWorkspaces?: string[];
  }>();

  const shouldHideLogo = useMemo(() => {
    return !!workspaceId && !!whiteLabelWorkspaces?.length && whiteLabelWorkspaces.includes(workspaceId);
  }, [whiteLabelWorkspaces, workspaceId]);

  return (
    <div className="relative flex h-14 items-center justify-between bg-grey-3 px-3" data-testid={PUBLIC_TOP_BAR}>
      <div>{workspaceInfo}</div>

      <div className="flex items-center gap-4">
        {accountInfo ? accountInfo : <PublicSignUpButton />}
        {!shouldHideLogo && (
          <Link href={Routes.root}>
            <Air className="w-[48px] text-blue-9" data-testid={PUBLIC_TOP_BAR_LOGO} />
          </Link>
        )}
      </div>
    </div>
  );
});

PublicTopBar.displayName = 'PublicTopBar';
