import { ShortUrl } from '@air/api';
import { Spinner } from '@air/primitive-spinner';
import { useShortIdContext } from '@air/provider-short-id';
import dynamic from 'next/dynamic';
import { memo, useCallback } from 'react';

import { useCanAnnotateCentralizedPublicClip } from '~/components/Annotations/hooks/useCanAnnotateCentralizedPublicClip';
import { PDFVisualizerProps } from '~/components/AssetModal/Visualizer/PDFVisualizer/PDFVisualizer';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { usePublicLinkAddOns } from '~/swr-hooks/workspaces/addOns/usePublicLinkAddOns';
import { canViewAddOns } from '~/utils/permissions/workspacePermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

const NewPDFVisualizer = dynamic<PDFVisualizerProps>(
  () =>
    import('~/components/AssetModal/Visualizer/PDFVisualizer/PDFVisualizer')
      .then((mod) => mod.PDFVisualizer)
      .catch((e) => {
        console.error('ERROR', e);
        // eslint-disable-next-line react/display-name
        return () => (
          <div className="flex size-full items-center justify-center text-white">Something went wrong</div>
        );
      }),
  {
    ssr: false,
    loading: () => (
      <div className="flex size-full items-center justify-center">
        <Spinner className="size-8 text-teal-9" />
      </div>
    ),
  },
);

export const PublicPDFVisualizer = memo(() => {
  const { canEditAnnotation } = useCanAnnotateCentralizedPublicClip();

  const { permissions } = usePublicPermissions();
  const { shortId } = useShortIdContext();
  const canSeeAddOns = canViewAddOns(permissions);
  const clipId = useAirSelector(centralizedClipIdSelector); // clipId may not be in url if it is public clip link
  const { data: addOns } = usePublicLinkAddOns({ enabled: canSeeAddOns, shortId });

  const isDocumentTextAddOnEnabled = !!addOns?.data.some(
    (addOn) => addOn.key === 'documentText' && addOn.enabled && addOn.visible,
  );

  const getFallbackPDFUrl = useCallback(async () => {
    if (!clipId) return '';
    const { url } = await ShortUrl.getDownloadUrl(shortId, clipId);
    return url;
  }, [clipId, shortId]);

  return (
    <NewPDFVisualizer
      getFallbackPDFUrl={getFallbackPDFUrl}
      canAnnotateClip={canEditAnnotation}
      insertSearchFromQuery={isDocumentTextAddOnEnabled}
    />
  );
});

PublicPDFVisualizer.displayName = 'PublicPDFVisualizer';
